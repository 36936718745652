$(function(){
  $(':input[type=number]').on('mousewheel',function(e){ $(this).blur(); });
});

// The function actually applying the offset
function offsetAnchor() {
  if (location.hash.length !== 0) {
    window.scrollTo(window.scrollX, window.scrollY - 100);
  }
}

// Captures click events of all <a> elements with href starting with #
$(document).on('click', 'a[href^="#"]', function(event) {
  // Click events are captured before hashchanges. Timeout
  // causes offsetAnchor to be called after the page jump.
  window.setTimeout(function() {
    offsetAnchor();
  }, 0);
});

// Set the offset when entering page with hash present in the url
window.setTimeout(offsetAnchor, 0);

$(function() {
  $('.easy-pie-chart').easyPieChart();
});

$(document).ready(function(){
  // $('.date-picker').flatpickr();

  $('#per_hour_rates').on('cocoon:after-insert', function(e) {
    //  $('.date-picker').flatpickr();
  });

  // CHARGEABLE

  var totalChargeableHours = 0;

  $('.chargeable-item-hours').each(function(){
    if ($(this).val() === "0") {
      $(this).css("color", "transparent");
    }
    
    totalChargeableHours += parseFloat($(this).val());
  });

  $('#total_chargeable_hours_calculated').text(totalChargeableHours);
  
  $(".chargeable-item-hours").change(function(){
    var totalChargeableHours = 0;

    $('.chargeable-item-hours').each(function(){
      totalChargeableHours += parseFloat($(this).val());
    });

    $('#total_chargeable_hours_calculated').text(totalChargeableHours);
  });

  $(".chargeable-item-hours").on("input", function() {
    if ($(this).val() !== "0") {
      $(this).val(parseFloat($(this).val()));
      $(this).css("color", "red");
    }
  });

  // NON-CHARGEABLE

  var totalNonChargeableHours = 0;

  $('.non-chargeable-item-hours').each(function(){
    if ($(this).val() === "0") {
      $(this).css("color", "transparent");
    }

    totalNonChargeableHours += parseFloat($(this).val());
  });

  $('#total_non_chargeable_hours_calculated').text(totalNonChargeableHours);
  
  $(".non-chargeable-item-hours").change(function(){
    var totalNonChargeableHours = 0;

    $('.non-chargeable-item-hours').each(function(){
      totalNonChargeableHours += parseFloat($(this).val());
    });

    $('#total_non_chargeable_hours_calculated').text(totalNonChargeableHours);
  });

  $(".non-chargeable-item-hours").on("input", function() {
    if ($(this).val() !== "0") {
      $(this).val(parseFloat($(this).val()));
      $(this).css("color", "red");
    }
  });
});